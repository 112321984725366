import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/fr/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/fr/lexique/">Lexique</a>
                    </li>
                    <li className="last">
                      <a href="/fr/faq-questions-diarrhee/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li id="uilist-hor nav-top nav-lang_nl-BE">
                      <a href="/privacybeleid/">NL</a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr" className="active">
                      <a href="/fr/respect-de-la-vie-privee/">FR</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="d2d2ffaa91b0c3345d0cd27cec2f412480a51055"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/fr/chercher"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <span className="logo">
                  <a href="/fr/">Imodium®</a>
                </span>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/fr/comment-agit-imodium/">
                          Comment agit
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/fr/medicaments-imodium/">
                          Médicaments <br />
                          IMODIUM®
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/fr/la-diarrhee/">
                          Diarrhée – causes
                          <br />
                          et traitement
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/fr/diarrhee-en-voyage/">
                          Diarrhée
                          <br />
                          en voyage
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/fr/diarrhee-chez-les-enfants/">
                          Diarrhée chez
                          <br />
                          les enfants
                        </a>
                      </li>                      
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview last">
                <a href="/fr/respect-de-la-vie-privee/">
                  {" "}
                  Respect de la vie privée
                </a>
              </li>
            </ul>
          </div>
          <div className="page nine columns page-39">
            <h1>Respect de la vie privée</h1>
            <h1>Politique de confidentialité</h1>
            <p>
            Johnson &amp; Johnson Consumer S.A. s’intéresse aux questions touchant à la confidentialité et souhaite que vous vous familiarisiez avec la manière dont nous recueillons, utilisons et divulguons les informations. La présente déclaration de confidentialité décrit nos pratiques concernant les informations que nous ou nos prestataires de services recueillons à partir du site Web ou de l’application (ci-après dénommés le <strong>« Service »)</strong> que nous exploitons et contrôlons, depuis lequel vous consultez la présente politique de confidentialité. En nous communiquant des données personnelles ou en utilisant le Service, vous reconnaissez avoir pris connaissance de la présente déclaration de confidentialité.
            </p>
            <h2>UTILISATION PAR DES MINEURS</h2>
            <p>
            Le Service n’est pas destiné à des personnes dont l’âge est inférieur à 13 et nous insistons sur le fait que ces personnes ne doivent pas fournir des données personnelles via le Service. Si votre enfant a transmis des données personnelles et que vous en souhaitez la suppression, veuillez nous contacter en suivant les explications ci-dessous dans la rubrique Nous contacter.
            </p>
            <h2>COLLECTE D’INFORMATIONS</h2>
            <p>
            Nous pouvons vous demander de fournir des données personnelles afin de vous permettre de bénéficier de fonctionnalités spécifiées (notamment les abonnements au bulletin d’information, des conseils/indications ou le traitement de la commande) ou de participer à une activité particulière (notamment des tirages au sort ou autres promotions). Vous serez informé des informations obligatoires et de celles qui sont facultatives.
              <br />
              Nous pouvons combiner les informations que vous fournissez avec d’autres informations que nous avons recueillies vous concernant, en ligne ou hors connexion, comme par exemple l’historique de vos achats. Nous pouvons également les combiner avec des informations vous concernant obtenues de nos filiales Kenvue.
              <br />
              Si vous nous soumettez des informations personnelles relatives à une autre personne, vous reconnaissez avoir la capacité de le faire et vous nous donnez la permission d’utiliser les informations conformément à la présente politique de confidentialité.
            </p>
            <h2>INFORMATIONS SENSIBLES</h2>
            <p>
            Sauf sur demande ou invitation expresse de notre part, vous ne devez pas nous envoyer ou divulguer de données personnelles sensibles (p. ex., numéro de sécurité sociale, informations relatives à l’origine raciale ou ethnique, opinions politiques, religion ou autres croyances, conditions de santé ou médicales, activités ou orientations sexuelles, casier judiciaire, adhésion à un syndicat ou données biométriques ou génétiques) via le Service ou par tout autre moyen.
            </p>
            <h2>COLLECTE ET UTILISATION AUTOMATIQUES D’INFORMATIONS</h2>
            <p>
            Nos prestataires de services et nous-mêmes pouvons recueillir automatiquement des données lorsque vous naviguez sur le site ou utiliser le Service Veuillez consulter la {" "}
              <a href="/fr/politique-en-matiere-de-cookies/" target="_blank">
                Politique en matière de cookies
              </a>{" "}
              pour les informations détaillées sur les cookies et autres technologies de suivi utilisées par le Service. Sauf dans les cas où ce serait autorisé par la loi, nous utilisons les cookies seulement après avoir reçu votre consentement pour leur utilisation, soit à travers le bandeau de cookie soit à travers le gestionnaire de consentement centralisé. La Politique en matière de cookies contient des informations vous expliquant comment désactiver ces technologies. 
              <br />
              Nos prestataires de services et nous-mêmes pouvons également recueillir automatiquement des données et les utiliser de la façon suivante :
            </p>
            <p>
              <strong>À partir de votre navigateur</strong>: certaines informations sont recueillies par la plupart des navigateurs, notamment votre adresse MAC (Media Access Control ou contrôle d’accès au support), le type d’ordinateur (Windows ou Macintosh), la résolution d’écran, le nom et la version du système d’exploitation, ainsi que le type de navigateur Internet et sa version. Nous pouvons recueillir des informations similaires, notamment votre type de périphérique et votre identifiant, si vous accéder au Service à partir d’un appareil mobile. Nous utilisons ces informations pour assurer le bon fonctionnement du Service.
            </p>
            <p>
              <strong>Adresse IP</strong>: votre adresse IP est le numéro qui est automatiquement attribué à votre ordinateur par votre fournisseur d’accès à Internet (FAI). Une adresse IP est identifiée et conservée automatiquement dans les journaux de connexion de notre serveur chaque fois qu’un utilisateur visite le Service, en même temps que l’heure de la visite et les pages qui ont été consultées. La collecte des adresses IP est une pratique standard effectuée automatiquement par de nombreux Services. Nous utilisons les adresses IP, par exemple, pour calculer les niveaux d’utilisation du Service, nous aider à diagnostiquer les problèmes de serveur et administrer le Service. Nous pouvons également évaluer votre emplacement à partir de votre adresse IP.
            </p>
            <p>
              <strong>Informations sur les périphériques</strong>: Nous pouvons recueillir des données concernant votre périphérique mobile, par exemple l’identifiant unique du périphérique, pour comprendre la façon dont vous utilisez le Service.
            </p>
            <h2>COMMENT NOUS UTILISONS ET DIVULGUONS LES INFORMATIONS</h2>
            <p>
            Nous utilisons et divulguons les informations que vous mettez à notre disposition au moment de la saisie, comme nous l’avons décrit. Veuillez consulter la section ci-dessous intitulée « Choix et accès » pour savoir comment vous désabonner de certaines fonctionnalités ou informations.
              <br />
              Lorsque la législation applicable le requiert, nous obtiendrons votre consentement avant d’utiliser vos données personnelles au moment de la saisie des informations.  Nous pouvons également utiliser des informations de votre part ou vous concernant lorsque nécessaire, dans l’exécution d’un contrat ou afin de respecter nos obligations légales (par exemple, en raison de nos obligations en matière de pharmacovigilance) ou pour nos besoins professionnels légitimes.  Nous pouvons aussi nous appuyer sur d'autres bases juridiques à des fins spécifiques :
            </p>
            <ul>
              &nbsp; &nbsp;
              <li><strong>
                Assurer la prestation du Service et satisfaire vos demandes.
              </strong>
              <li>
              vous faire bénéficier de la prestation du Service et du service client correspondant.
              </li>
              <li>
              répondre à vos demandes d’information et satisfaire vos demandes, notamment vous envoyer les documents que vous demandez ou des courriers électroniques d’alerte.
              </li>
              <li>
              vous envoyer des informations importantes concernant la relation existant entre nous ou concernant le Service, les modifications de nos conditions générales et de nos politiques, ou d’autres informations à caractère administratif.
              </li>
              </li>
            </ul>
            <p>
            Nous initierons ces actions dans le cadre de la relation contractuelle qui nous lie à vous ou pour respecter nos obligations légales.
            </p>
            <ul>
              &nbsp;{" "}
              <li><strong>
                &nbsp;Assurer le bon fonctionnement de l'entreprise.
              </strong>
              <li>
              pour l'analyse des données, par exemple, afin d'améliorer l'efficacité du Service.
              </li>
              <li>
              pour les audits, pour vérifier que nos processus internes fonctionnent comme prévu et sont conformes à nos obligations légales, réglementaires ou contractuelles.
              </li>
              <li>
              pour le contrôle des fraudes et de la sécurité, par exemple, pour détecter et empêcher les cyberattaques ou les tentatives d'usurpation d'identité.
              </li>
              <li>pour le développement de nouveaux produits et services.</li>
              <li>
              pour améliorer ou modifier notre site internet ou nos produits et nos services.
              </li>
              <li>
              pour recenser les types d'utilisation du Service, par exemple, afin d'identifier les aspects de notre Service qui présentent le plus d'intérêt pour les utilisateurs.
              </li>
              <li>
              pour mesurer l'efficacité de nos campagnes promotionnelles, afin de les adapter au mieux aux besoins et aux centres d'intérêt de nos utilisateurs.
              </li>
              </li>
            </ul>
            <p>
            Nous initierons ces actions dans le cadre de la relation contractuelle qui nous lie à vous, pour respecter nos obligations légales ou parce que nous y avons un intérêt légitime.
            </p>
            <ul>
              &nbsp; &nbsp;
              <li><strong>
                L'analyse des données personnelles pour proposer des services
                sur mesure.
              </strong>
              <li>
              pour mieux vous comprendre, afin de personnaliser nos échanges avec vous et vous proposer des informations ou des offres adaptées à vos centres d'intérêt.
              </li>
              <li>
              pour mieux cerner vos préférences et donc vous apporter, dans le cadre du Service, du contenu qui, selon nous, vous concernera et vous intéressera.
              </li>
              </li>
            </ul>
            <p>
            Nous vous proposerons des services personnalisés, avec votre consentement ou parce que nous y avons un intérêt légitime.
            </p>
            <p>
            Nous divulguons également les informations recueillies par le Service :
            </p>
            <ul>
              <li>
              à nos filiales pour les objectifs décrits dans la présente déclaration de confidentialité. Une liste de nos filiales est disponible sur <a target="_blank" href="https://investors.kenvue.com/financials-reports/sec-filings/default.aspx">https://investors.kenvue.com/financials-reports/sec-filings/default.aspx</a> - accéder aux documents déposés par Kenvue auprès de la Securities Exchange Commission. Johnson &amp; Johnson Consumer S.A. est la partie responsable de la gestion des données personnelles utilisées conjointement ;
              </li>
              <li>
              avec nos partenaires tiers avec qui nous offrons une promotion conjointe
              </li>
              <li>
              avec nos prestataires de services tiers qui fournissent des services tels que l’hébergement et la modération de sites Web, l'hébergement d'applications mobiles, l’analyse de données, le traitement des paiements, l'exécution des commandes, la mise à disposition d’infrastructures, les services informatiques, les services clients, les services de courrier électronique et de courrier postal, l’audit et autres services, afin de leur permettre d’assurer les services ; et
              </li>
              <li>
              conformément à la loi applicable, avec des tiers en cas de réorganisation, fusion, vente, coentreprise, assignation, transfert ou autre cession de tout ou partie de nos activités, actifs ou actions (notamment dans le cadre d’une procédure de faillite ou toute autre procédure similaire).
              </li>
            </ul>
            <p>
            En outre, nous pouvons utiliser et divulguer les informations que nous jugeons nécessaires ou appropriées : (a) conformément aux procédures judiciaires ou à la loi applicable, y compris les lois en vigueur à l’extérieur de votre pays de résidence ; (b) conformément à la loi applicable, pour répondre aux demandes émanant des autorités publiques et gouvernementales, y compris les autorités publiques et gouvernementales à l’extérieur de votre pays de résidence ; (c) pour assurer l’application de nos conditions générales ; (d) pour protéger nos droits, notre vie privée, notre sécurité ou nos biens et ceux de nos filiales, les vôtres ou ceux d’autres personnes. Nous pouvons également utiliser ou divulguer vos informations d’autres façons après avoir obtenu votre consentement.
              <br />
              Nous pouvons utiliser et divulguer les informations que nous recueillons automatiquement tel que décrit ci-dessus sous « Collecte et utilisation automatiques d’informations ».
              <br />
              En outre, lorsque la loi l’y autorise, nous pouvons utiliser et divulguer des informations qui ne se présentent pas sous une forme personnellement identifiable pour tout autre motif. S’il nous arrive de combiner des informations qui ne se présentent pas sous une forme personnellement identifiable avec des informations qui le sont (par exemple, combiner votre nom avec votre emplacement géographique), nous traiterons les informations combinées comme des données personnelles aussi longtemps qu’elles resteront combinées.
            </p>
            <h2>CHOIX ET ACCÈS</h2>
            <p>
              <strong>
              Vos choix en ce qui concerne la manière dont nous utilisons et divulguons vos données personnelles
              </strong>
            </p>
            <p>
            Nous vous offrons des choix en ce qui concerne la manière dont nous utilisons et divulguons vos données personnelles à des fins de marketing. Vous avez le choix entre les options suivantes :
            </p>
            <ul>
              <li>
                <strong>
                  Recevoir des communications à des fins de marketing envoyées
                  par nous&nbsp;:
                </strong>{" "}
                Si vous ne souhaitez plus recevoir nos communications à des fins de marketing, vous pouvez choisir de ne plus les recevoir en nous contactant par {" "}
                <a
                  href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p1"
                  target="_blank"
                >
                  formulaire de contact
                </a>
                . Dans la demande que vous nous envoyez, veuillez préciser votre nom, identifier la ou les formes de communication que vous souhaitez ne plus recevoir et indiquer les adresses auxquelles celles-ci sont envoyées. Par exemple, si vous souhaitez ne plus recevoir de prospection commerciale par voie électronique (marketing électronique) ou de courrier postal de notre part, il vous suffit de nous le dire en indiquant votre nom et votre adresse de courrier électronique ou postal. En outre, vous pouvez choisir de ne plus recevoir de messages de prospection commerciale (marketing électronique) par courriers électroniques de notre part en suivant les instructions relatives au désabonnement incluses dans chacun de ces messages.
              </li>
              <li>
                <strong>Réception de rappels&nbsp;:</strong> Si vous souhaitez désormais ne plus recevoir des rappels médicaux de notre part, vous pouvez confirmer votre choix en nous contactant par {" "}
                <a
                  href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p1"
                  target="_blank"
                >
                  formulaire de contact
                </a>
                . Dans la demande que vous nous envoyez, veuillez indiquer votre nom et l’adresse électronique ou le numéro de téléphone par lesquels vous recevez ces rappels de notre part.
              </li>
              <li>
                <strong>
                  Modalités de partage de vos données personnelles avec les
                  filiales et les partenaires tiers&nbsp;:
                </strong>{" "}
                Si vous vous étiez abonné aux communications marketing de nos filiales ou partenaires tiers, vous pouvez vous désabonner du partage de vos données personnelles auprès de ces tiers à des fins de marketing direct régulier de leur part en nous contactant par {" "}
                <a
                  href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p1"
                  target="_blank"
                >
                  formulaire de contact
                </a>
                . Dans votre demande, veuillez préciser que nous ne devons plus partager vos données personnelles avec nos filiales et partenaires tiers pour leurs besoins de marketing en indiquant votre nom et votre adresse électronique.
              </li>
            </ul>
            <p>
            Nous nous efforcerons de satisfaire votre demande dans les meilleurs délais. Notez toutefois que si vous vous désabonnez comme il est indiqué ci-dessus, nous ne pourrons pas supprimer vos données personnelles des bases de données des filiales à qui elles ont déjà été communiquées (c.-à-d., avant réception de votre demande de désabonnement). Toutefois, nous ferons des efforts raisonnables pour informer nos filiales de votre demande. Veuillez noter que, même si vous choisissez de vous désabonner de nos messages marketing, nous pourrons continuer de vous envoyer des messages commerciaux et administratifs importants et vous ne pourrez pas refuser de les recevoir.
            </p>
            <p>
              <strong>
                Comment accéder à vos données personnelles, les modifier ou les
                supprimer
              </strong>
            </p>
            <p>
            Si vous souhaitez modifier, corriger, mettre à jour, restreindre ou supprimer vos données personnelles, ou si vous souhaitez demander une copie électronique de vos données personnelles pour les transmettre à une autre société (dans la limite de vos droits conformément à la législation applicable), veuillez nous contacter par {" "}
              <a
                href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p1"
                target="_blank"
              >
                formulaire de contact
              </a>
              . Nous répondrons à votre demande dans les meilleurs délais, au plus tard un mois après la réception de votre demande. S’il existe des circonstances entraînant un retard dans notre réponse, vous en serez averti dans les meilleurs délais avec la date à laquelle nous serons en mesure de vous répondre.  
            </p>
            <h2>TRANSFERT TRANSFRONTALIER</h2>
            <p>
            Vos données personnelles peuvent être stockées et traitées dans tout pays où nous avons des installations ou des prestataires de services. Lorsque vous utilisez notre Service ou lorsque vous donnez votre consentement (si la loi l’exige), vos informations sont susceptibles d’être transférées vers des pays autres que votre pays de résidence, notamment aux États-Unis, qui peuvent prévoir des règles de protection des données différentes de celles qui sont en vigueur dans votre pays. Néanmoins, des dispositions contractuelles et autres sont en place pour protéger les données personnelles transférées vers nos filiales ou partenaires tiers d’autres pays.
              <br />
              Certains pays à l’extérieur de l’Espace économique européen (EEE) sont reconnus par la Commission européenne pour leur niveau adéquat de protection des données selon les normes de l’EEE (la liste exhaustive de ces pays est disponible {" "}
              <a
                href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en"
                target="_blank"
              >
                ici
              </a>
              ). En ce qui concerne les transferts de pays de l’EEE qui ne sont pas reconnus comme étant adéquats par la Commission européenne, nous avons garanti la mise en place de mesures adéquates, dont la confirmation que le destinataire est lié par les clauses contractuelles standard de l’UE pour protéger vos données confidentielles. Vous pouvez obtenir une copie de ces mesures en contactant notre délégué à la protection des données conformément à la rubrique « Nous contacter » ci-dessous.
            </p>
            <h2>SÉCURITÉ</h2>
            <p>
            Nous essayons de mettre en œuvre des mesures organisationnelles, techniques et administratives raisonnables conçues pour protéger les données personnelles que nous traitons. Malheureusement, aucun système de sécurité ou de transfert de données sur Internet ne peut garantir une sécurité à 100 %. Si vous avez des raisons de croire que votre interaction avec nous n’est plus sécurisée (par exemple, si vous avez l’impression que la sécurité d’un compte que vous avez chez nous a été compromise), veuillez immédiatement nous aviser conformément à la section « Nous contacter » ci-dessous.
            </p>
            <h2>DÉLAI DE CONSERVATION</h2>
            <p>
            Nous conserverons vos informations personnelles aussi longtemps que nécessaire ou qu’autorisé selon la finalité pour laquelle elles ont été recueillies. Les critères utilisés pour déterminer les délais de conservation incluent : (i) la durée de nos relations et de nos prestations de services avec vous-même ; (ii) la présence d’une obligation légale à laquelle nous sommes assujettis ; et (iii) si la conservation est recommandée au vu de notre situation juridique (notamment en vue des délais de prescription, litiges, ou enquêtes réglementaires applicables).
              <br />
              Par exemple, si nous recueillons vos données personnelles pour conclure un contrat, nous les conserverons pour une durée de 15 ans à partir de l’expiration du contrat.
              <br />
              Au cas où vous auriez consenti à recevoir des informations marketing, nous conservons vos données personnelles pour une durée de 5 ans ou jusqu’à ce que vous retiriez votre consentement.
              <br />
              Cependant, veuillez noter qu’il ne s’agit pas d’une liste exhaustive des durées de conservation. Vos données personnelles pourront être conservées pour une durée supplémentaire selon les critères décrits dans le premier paragraphe de cette section, particulièrement les points (ii) et (iii).
            </p>
            <h2>SITES ET SERVICES TIERS</h2>
            <p>
            Ce service peut contenir des liens vers des sites de tiers. La présente politique de confidentialité ne traite pas, et nous n’en sommes aucunement responsables, de la confidentialité des données, des informations ou des pratiques de tiers, notamment des tiers exploitant un site ou service en ligne quelconque (notamment, sans s’y limiter, toute application) accessible via ce site ou dont le lien se trouve sur ce site. La disponibilité ou l’intégration d’un lien vers un tel site ou une telle propriété du Site n’implique nullement notre approbation ou celle de nos filiales.
            </p>
            <h2>NOUS CONTACTER</h2>
            <p>
            Johnson & Johnson Consumer S.A., situé à Anvers, est la Société responsable de la collecte, de l’utilisation, et de la divulgation des données personnelles de la présente politique de confidentialité.
              <br />
              Si vous avez des questions sur la présente politique de confidentialité, veuillez nous contacter par {" "}
              <a
                href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p1"
                target="_blank"
              >
                formulaire de contact
              </a>{" "}
              ou écrire à l’adresse suivante&nbsp;:
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;Johnson &amp; Johnson Consumer S.A.
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;Michel de Braeystraat 52
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;2000 Antwerpen
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;Belgique
              <br />
              Vous pouvez également contacter le délégué à la protection des données responsable de votre pays ou région le cas échéant, à l’adresse <a href="mailto:emeaprivacy@kenvue.com">emeaprivacy@kenvue.com</a>.
            </p>
            <h2>DÉPOSER UNE PLAINTE AUPRÈS D’UN RÉGULATEUR</h2>
            <p>
            Vous pouvez déposer une plainte auprès de l’autorité de surveillance compétente de votre pays ou région. Veuillez cliquer {" "}
              <a
                href="http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm"
                target="_blank"
              >
                ici
              </a>{" "}
              pour consulter les coordonnées de ces autorités. 
            </p>
            <h2>MISES À JOUR DE LA PRÉSENTE DÉCLARATION DE CONFIDENTIALITÉ</h2>
            <p>
            Nous pouvons modifier la présente politique de confidentialité. Toutes les modifications apportées à la présente politique de confidentialité prennent effet à compter de la date de publication de la dernière version du Site. Si vous continuez d’utiliser le Service après ces modifications, vous acceptez la version révisée de la Déclaration de confidentialité. Nous vous recommandons de consulter régulièrement la politique de confidentialité lorsque vous utilisez le Service. La dernière mise à jour de la présente politique a eu lieu le 04-05-2023.
            </p>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-fr.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="fr" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
